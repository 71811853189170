import React, { useEffect, useState } from 'react';
import GChatBTN from '../../consts/Chats/ChatButtons/GChatBTN';
import { postRequest } from '../../consts/Server/Requests';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import { RenewalActions } from './RenewalActions/RenewalActions';
import TagsShow from '../../consts/TagsShow/TagsShow';
import uuid from 'react-uuid';
import SentenceShorter from '../../consts/SentenceShorter';
import ToolTips from '../../consts/Tooltip/ToolTips';
import Call_logs from '../Old_JobList/Components/InputBox/Call_logs';
import moment from 'moment';
import { DueDateEditor } from '../../consts/Dialogbox/DueDate/DueDateEditor';
import LastStatusEditor from '../../consts/LastStatusEditor/LastStatusEditor';
import IsWhatsAppEnabled from '../../consts/components/IsWhatsAppEnabled';
import { catchCurrentOpenedUrl } from '../../consts/components/Utility';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdminLastStatusCustomerForm from '../admins/adminComponents/AdminLastStatusCustomerForm';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import PermissionContextProvider from '../admins/adminComponents/PermissionContextProvider';
import { adminMenuActionPermissionKeys } from '../admins/adminConstant';
const userid = JSON.parse(localStorage.getItem("user") || "0")
export const columns = (handleFetchAllCallLogs?: any, handleFetchTransactionsClick?: any, customerData?: any, totalRowDataForCustomerDashboard?: any) => [
    {
        name: 'ACTION',
        cell: (row: any) => (
            <div className='d-flex flex-row'>
                <div className=' me-5'>
                    <RenewalActions subscriptionid={row?.id} vehicleid={row?.vehicleid} customerid={row?.customerid} renewalreasonid={row?.renewal_reason_id} row={row} ></RenewalActions>
                </div>
            </div>
        ),
        minWidth: "50px",
        reorder: true,
        id: 0,

    },
    {
        name: 'ID',
        cell: (row: any) => (
            row?.id
        ),
        minWidth: "50px",
        reorder: true,
        id: 1,

    },
    {
        name: "Name",
        cell: (row: any) => {
            return (
                <div className='d-flex  '>


                    <PermissionContextProvider keyName={adminMenuActionPermissionKeys["renewal-subscription-whatsapp-notification-single"]}>
                        <IsWhatsAppEnabled
                            row={row}
                            Ids={row?.tickets?.length > 0 ? row?.tickets[0]?.id : 0}
                            templateType={1}
                            keyName="ticketIds"
                            ApiEndUrl="sendBulkWhatsAppTemplate"
                            to="Customer"
                            IsRequired={true}
                        />
                    </PermissionContextProvider>


                    <div className='d-flex flex-column'>
                        <div className='d-flex flex-row    text-hover-primary cursor-pointer '>
                            <Chats row={row} trackData={customerData} totalRowDataForCustomerDashboard={totalRowDataForCustomerDashboard}></Chats>
                        </div>
                        <div className=' d-flex  text-dark fs-8 fw-bold flex-wrap ms-1'>
                            <TagsShow Tag={row?.ctCustomer?.private_tag}></TagsShow>
                        </div>
                    </div>
                </div>
            )
        },
        sortable: true,
        reorder: true,
        id: 2,
        sortField: 'name',
        minWidth: "250px",
        custom: "custom"
    },
    {
        name: "P2P",
        cell: (row: any) => <UpdateLastStatus row={row} />,
        sortable: true,
        reorder: true,
        id: 3,
        sortField: 'renewal_reason_id',
        minWidth: "180px",
    },
    {
        name: <CallDetailColumn handleFetchAllCallLogs={handleFetchAllCallLogs} />,
        cell: (row: any) => <CallDtails row={row} />,
        reorder: true,
        id: 4,
        minWidth: "250px",
        hideCols:"renewal-subscription-fetch-call-logs"
    },

    {
        name: "Due Date",
        cell: (row: any) => {
            let render: any = <DueDateEditor row={row} from={"renewal"} permissionKey="renewal-subscription-update-due-date" />
            return render
        },
        sortable: true,
        reorder: true,
        id: 5,
        sortField: 'due_date',
        minWidth: "200px"
    },
    {
        sortable: true,
        reorder: true,
        id: 6,
        name: "Package Name",
        selector: (row: any) => `(${row?.packageid}) ${row.packagename}`,
        // sortField: 'packagename',
        width: "250px"
    },
    {
        name: "Overdue Days",
        selector: (row: any) => row.transactionid,
        reorder: true,
        id: 7,
        // sortField: 'transactionid',
    },
    {
        name: "Start Date",
        selector: (row: any) => row.startdate,
        sortField: 'startdate',
        sortable: true,
        reorder: true,
        id: 8,
    },
    {
        name: "End Date",
        selector: (row: any) => row.enddate,
        sortField: 'enddate',
        sortable: true,
        reorder: true,
        id: 9,
    },
    {
        name: <TransactionsColumn handleFetchTransactionsClick={handleFetchTransactionsClick} />,
        cell: (row: any) => <LastTranactions row={row} />,
        // sortable: true,
        reorder: true,
        id: 10,
        minWidth: "200px",
        hideCols:"renewal-subscription-fetch-transaction"
    },
];



const UpdateLastStatus = ({ row }: any) => {
    const [show, setShow] = useState(false)
    const [defaultLastStatus, setDefaultLastStatus] = useState<any>(null)
    const [rowData, setRowData] = useState<any>(row)
    const lastStatusListData = useSelector((store: any) => store.ReasonsListDataReducer.renewalReason)

    useEffect(() => { setRowData(row) }, [row])

    useEffect(() => {
        const lastStatusName = lastStatusListData?.find((ele: any) => {
            return ele?.id === row?.renewal_reason_id
        })

        lastStatusName && setDefaultLastStatus(lastStatusName)
    }, [lastStatusListData, rowData?.renewal_reason_id])

    const setUpdatedData = (payload: any) => {
        row['renewal_reason_id'] = +payload?.renewalreasonid
        setDefaultLastStatus(payload?.item)
        setRowData(row)
    }

    return (
        <div className='d-flex flex-row'>
            <span >
                {defaultLastStatus?.name || 'Not Set'} &nbsp;

                <PermissionContextProvider keyName={adminMenuActionPermissionKeys["renewal-subscription-update-p2p"]}>
                    <FontAwesomeIcon onClick={() => setShow(true)} icon={faEdit} />
                </PermissionContextProvider>
            </span>
            {
                show ? <AdminLastStatusCustomerForm
                    setShow={setShow}
                    show={show}
                    row={row}
                    defaultLastStatus={defaultLastStatus ? { label: defaultLastStatus?.name, value: defaultLastStatus?.id } : null}
                    setUpdatedData={setUpdatedData}
                    lastStatusListData={lastStatusListData}
                    initialData={{
                        subscriptionid: row?.id,
                        renewalreasonid: row?.renewal_reason_id,
                    }}
                /> : <></>
            }
        </div>
    )
}





const Chats = ({ row, trackData, totalRowDataForCustomerDashboard }: any) => {
    const [ticketid, setTicketid] = useState<any>(null)
    const [ticketidNo, setticketidNo] = useState<any>(null)
    const [customerid, setcustomerid] = useState<any>(null)
    const [subscriptionid, setsubscriptionid] = useState<any>(null)
    const handleClickChat = async (result: any) => {
        const response: any = await postRequest(URL_EndPoints(null)?.getTicketRenewalSubscription, {
            customerid: result?.customerid,
            subscriptionid: result?.id,
            user_id: userid,
        }, null)
        setsubscriptionid(response?.data?.data?.subscription_id)
        setTicketid(response?.data?.data?.id)
        setcustomerid(row?.customerid)
    }
    useEffect(() => {
        setticketidNo(ticketid)
    }, [ticketid])

    const dueDate = row?.tickets?.length > 0 && row?.tickets[0].last_date_resolution != "" ? row?.tickets[0].last_date_resolution : "Not Set"
    return <>
        <div onClick={() => handleClickChat(row)} >
            <GChatBTN
                ticketDataParent={{
                    id: ticketidNo,
                    customerid: customerid,
                    random: Math.random(),
                    source_status: "Update P2P",
                    subscriptionid: subscriptionid,
                    reasonid: row?.renewal_reason_id,
                    BTNColor: row?.customerid,
                    source_due_date: "source_due_date",
                    defaultDueDate: dueDate,
                    referenceTicket: null
                }}
                trackData={trackData}
                totalRowDataForCustomerDashboard={totalRowDataForCustomerDashboard}
                currentRowDataFromList={row}
                keyName={catchCurrentOpenedUrl()}
                Title={row?.name}
                Toggleid={"kt_drawer_chat2_toggle2"}
                classes={"text-hover-primary"}
                icons={""}
                refs="renewal"
            ></GChatBTN>
        </div>
    </>
}

const CallDetailColumn = ({ handleFetchAllCallLogs }: any) => {
    return <>
        Call Details <button className='btn btn-primary btn-sm btn-rounded' onClick={handleFetchAllCallLogs} style={{
            fontSize: '10px',
            padding: '5px 7px',
            marginLeft: '7px'
        }}>Fetch All</button>
    </>
}

const CallDtails = ({ row }: any) => {
    const [storedRow, setStoredRow] = useState<any>(row);

    useEffect(() => {
        setStoredRow(row);
    }, [row]);

    return <div className='card-title'>
        <div className='symbol-group symbol-hover'>
            {storedRow?.callLogs?.map((ele: any, indexs: any) => {
                let id = uuid()
                let StartTime = ele?.FormattedStartTime?.split(" ")
                let sentence = ele?.FromDetails?.name + ' - ' + ((StartTime[0] + " " + StartTime[1]))
                if (indexs < 5) {
                    return <div className='symbol symbol-40px symbol-circle' id={id} key={id}  >
                        <span className={`symbol-label bg-light-${ele?.Status === "completed" ? "success" : ele?.Status === "failed" ? "warning" : ele?.Status === "no-answer" ? "secondary" : ele?.Status === "canceled" ? "danger" : "primary"} text-${ele?.Status === "completed" ? "success" : ele?.Status === "failed" ? "warning" : "primary"} 40px   fs-7`} >{StartTime[0]} <SentenceShorter sentence={StartTime[1]} index={null} /></span>
                        <ToolTips toolTipId={id} ToolTipShowData={sentence} place={""} />
                    </div>
                }
            })}
            {/* _______________________________________________________________________ */}
            <CallLogsDetails customerid={storedRow?.customerid} customer_name={storedRow?.ctCustomer?.first_name + ' ' + storedRow?.ctCustomer?.last_name} lengths={storedRow?.callLogs?.length} row={storedRow} setStoredRow={setStoredRow}></CallLogsDetails>
        </div>
    </div>
}

const CallLogsDetails = ({ customerid, customer_name, lengths, row, setStoredRow }: any) => {
    const [show, setshow] = useState(false)
    const [Cusomerid, setCusomerid] = useState(0)
    const handleFetchCallDetails = async (customerid: number) => {
        if (lengths === undefined) {
            const cell: any = document.querySelector(`#cell-11-${customerid}`);
            cell?.click();
            const response: any = await postRequest(URL_EndPoints()?.getCallHistoryByCustomerIds, {
                customerIds: [customerid]
            }, null);
            const callLogs: any = response?.data?.data[0];
            setStoredRow({ ...row, callLogs });
            setCusomerid(customerid)
        } else {
            setshow(true)
            setCusomerid(customerid)
        }
    }
    const handleClose = () => {
        setshow(false)
    }
    return (
        <>
            <a
                href='#'
                className='symbol symbol-40px symbol-circle'
                id={customerid}
                onClick={(e: any) => {
                    e.preventDefault();
                    handleFetchCallDetails(customerid);
                }}
            >
                <span
                    className='symbol-label fs-8 fw-bolder bg-primary text-white'
                >
                    {lengths === undefined ? 'Fetch' : lengths}
                </span>
                <ToolTips toolTipId={customerid} ToolTipShowData={lengths === undefined ? "View call logs" : "View All Call Details"} place={""} />
            </a>
            <Call_logs FilteredData={{
                call_log_cusomerid: Cusomerid,
                show: show,
                handleClose,
                customer_name
            }} >
            </Call_logs>
        </>
    )
}

const TransactionsColumn = ({ handleFetchTransactionsClick }: any) => {
    return <>
        Transactions <button className='btn btn-primary btn-sm btn-rounded' onClick={handleFetchTransactionsClick} style={{
            fontSize: '10px',
            padding: '5px 7px',
            marginLeft: '7px'
        }}>Fetch All</button>
    </>
}

const LastTranactions = ({ row }: any) => {
    const [storedRow, setStoredRow] = useState<any>(row);

    useEffect(() => {
        setStoredRow(row);
    }, [row])

    const handleFetchTransactions = async (customerid: number) => {
        const response: any = await postRequest(URL_EndPoints()?.getTransactions, {
            customerid: row?.customerid,
            doNotInclude: true
        }, null);
        const transactions = response?.data?.data;
        setStoredRow({ ...row, transactions });
    }

    return <div className='card-title bg-'>
        <div className='symbol-group symbol-hover'>
            {storedRow?.transactions?.length > 0 ? storedRow?.transactions?.map((ele: any, indexs: any) => {
                let id = uuid()
                let StartTimes = moment(new Date(ele?.createdAt)).fromNow()
                let StartTime = StartTimes?.split(" ")
                let sentence = ele?.cancellation_reason + ' - ' + ((StartTime[0] + " " + StartTime[1] + " " + StartTime[2]))
                if (indexs < 5) {
                    return <div className='symbol symbol-40px symbol-circle' id={id}  >
                        <span className={`symbol-label bg-light-${ele?.authorization_status === "success" ? "success" : "danger"} text-${ele?.authorization_status === "success" ? "success" : "danger"}  40px   fs-7`} >{StartTime[0]} <SentenceShorter sentence={StartTime[1]} index={null} /></span>
                        <ToolTips toolTipId={id} ToolTipShowData={sentence} place={""} />
                    </div>
                }
            })
                :
                <a
                    href='#'
                    className='symbol symbol-40px symbol-circle'
                    onClick={(e: any) => {
                        e.preventDefault();
                        handleFetchTransactions(storedRow?.customerid);
                    }}
                >
                    <span
                        className='symbol-label fs-8 fw-bolder bg-primary text-white'
                        id='notransaction'
                    >
                        Fetch
                    </span>
                    <ToolTips toolTipId="notransaction" ToolTipShowData="Fetch transactions" place={""} />
                </a>
            }
            {/* _______________________________________________________________________ */}
        </div>
    </div>
}