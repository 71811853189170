import React, { useRef, useState } from 'react'
import { AdminToolTip } from '../admins/adminComponents/AdminToolTipModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons'
const CardCountWidget = ({ className, color, data, custom_color = null, handleTabClick, mainTitle }: any) => {
    const headerRef = useRef<HTMLDivElement>(null)
    const childrenRef = useRef<HTMLDivElement>(null)

    const [isOpen, setIsOpen] = useState(true)

    let style: any = {
        backgroundColor: "#3AABFF",
        minHeight: "50px"
    }
    if (custom_color) {
        style.backgroundColor = custom_color
    }

    if (data?.style) {
        data.style = { ...data.style }
        data.style.minHeight = "50px"
    }

    const handleToggle = () => {
        if (headerRef.current && childrenRef.current) {
            if(isOpen) {
                headerRef.current.style.maxHeight = '5px';
                childrenRef.current.style.display = 'none';
                setIsOpen(false)
            } else {
                headerRef.current.style.maxHeight = '';
                childrenRef.current.style.display = 'block';
                setIsOpen(true)
            }
        }
    };

    return (
        <div className={` ${className}`}>
            <div className=' p-0'>

                <div className={`rounded-top totalCount`} style={data?.style ?? style} onClick={() => handleTabClick && handleTabClick(data?.filter, data?.tabKey, data?.isRedirect)} ref={headerRef}>
                    {/* <div className='d-flex justify-content-center text-center p-2 mb-2 w-100 bg-info'>
                        <h5 className='m-0 mt-1 text-white fw-semibold fs-6'>{mainTitle}</h5>
                    </div> */}
                    <div className='d-flex justify-content-center text-center'>
                        <h3 className='m-0 mt-1 text-white fw-bold fs-5'>{data?.title}</h3>
                    </div>
                    <div className='d-flex text-center flex-column text-white'>
                        <span className='fw-bold fs-2 pt-1'>{data?.total}</span>
                    </div>
                </div>
                <FontAwesomeIcon icon={isOpen ? faAngleDown : faAngleRight} className='ms-4 mt-1 fw-bold text-primary' onClick={handleToggle} style={{cursor: "pointer"}} />

                <div className='p-2' ref={childrenRef}>
                    {
                        data?.subData?.map((sub: any, index: any) => {
                            let toolTipId = sub?.title + "_" + index
                            return <div className='d-flex align-items-center mb-3' style={sub?.style} id={toolTipId}>
                                <div className='d-flex align-items-center flex-wrap w-100 subCount' onClick={() => handleTabClick ? handleTabClick(sub?.filter, data?.tabKey, data?.isRedirect) : ""}>
                                    <div className='mb-1 flex-grow-1 w-75' >
                                        <a href='#' className='fs-7 text-gray-800'>
                                            {sub?.title}
                                        </a>
                                    </div>
                                    {/* <div className='d-flex align-items-end border w-25 text-end'>
                                        <div className=' fs-7 text-gray-800 pe-1 text-end'> {sub?.total}</div>
                                    </div> */}
                                    <span className=' w-25 text-end'>
                                        {sub?.total}
                                    </span>
                                </div>
                                {sub?.titleTooltip && <AdminToolTip anchorId={toolTipId} title={sub?.titleTooltip} place={""} />}
                            </div>
                        })
                    }
                </div>

            </div>
        </div>
    )
}
export { CardCountWidget }
