import {useEffect} from 'react'
import {toast} from 'react-toastify'
import {postRequest} from '../../consts/Server/Requests'
import {URL_EndPoints} from '../../consts/Server/URL_EndPoints'
import {
  adminMenuActionPermissionKeys,
  enableFeatureList,
  enableFeatureList4CustomerDashbaord,
  filterApiList,
} from './adminConstant'
import AdminMultiDateFilter from './adminForms/adminFormComponents/AdminMultiDateFilter'
import AdminMultiSelectFilter from './adminForms/adminFormComponents/AdminMultiSelectFilter'
import AdminSelectCheckbox from './adminForms/adminFormComponents/AdminSelectCheckbox'
import AdminSelectTextBox from './adminForms/adminFormComponents/AdminSelectTextBox'
import AdminSingleDateFilter from './adminForms/adminFormComponents/AdminSingleDateFilter'
import AdminSingleSelectFilter from './adminForms/adminFormComponents/AdminSingleSelectFilter'
import AdminSelectTextAreaBox from './adminForms/adminFormComponents/AdminSelectTextAreaBox'
import * as CSS from 'csstype'
import moment from 'moment'
import AdminSelectJodEditor from './adminForms/adminFormComponents/AdminSelectJodEditor'
import {QueryParams} from '../../consts/Server/QueryParams'
import AdminTagsInput from './adminForms/AdminTagsInput'
import {useDispatch, useSelector} from 'react-redux'
import {getSmartFilterAction} from '../../../Redux/Reducer/RefreshPage/refreshPageActions'
function createUrlObject (url: any, method: any, params = null, query = null, body = null) {
  let createdUrlObj = {
    url: url,
    method: method,
    isQuery: null,
    isParams: null,
    isBody: null,
  }

  if (method === 'GET') {
    if (query) {
      createdUrlObj.isQuery = query
    }
    if (params) {
      createdUrlObj.isParams = params
    }
  } else if (method === 'POST') {
    if (body) {
      createdUrlObj.isBody = body
    }
  }
  return createdUrlObj
}

// let getWithQuery = createUrlObject('getAvailabilityTimeslots', 'GET', null, '?champid=2&categoryid=5');
// console.log(getWithQuery);

// let getWithParams = createUrlObject('getAvailabilityTimeslots', 'GET', '/3');
// console.log(getWithParams);

// let postWithBody = createUrlObject('submitForm', 'POST', null, null, { champid: 2, categoryid: 5 });
// console.log(postWithBody);

export const getFilterListAllInOne = (api: any, dynamicApiData?: any) => {
  return api
    .map((element: any) => {
      if (dynamicApiData && element?.filterApi in dynamicApiData) {
        return dynamicApiData[element?.filterApi]
      } else {
        if (element?.filterApi == 'whatsapp/getWhatsAppTemplate') {
          let wati = {...filterApiList[element?.filterApi]}
          wati.isBody = {}
          if (element.wa_number_id) {
            wati.isBody['wa_number_id'] = element.wa_number_id
          }
          element = wati
          return element
        }
        if (element?.filterApi == 'getAllMasterTags') {
          let master = {...filterApiList[element?.filterApi]}
          master.isBody = {}
          if (element.userType) {
            master.isBody['userType'] = element.userType
          }
          element = master
          return element
        }
        if (element?.filterApi == 'getMasterReasonForVisitors') {
          let master = {...filterApiList[element?.filterApi]}
          master.isQuery = 'categoryId=null'
          if (element.categoryId) {
            master.isQuery = `categoryId=${element.categoryId}`
          }
          element = master
          return element
        }
        if (element?.filterApi == 'getMasterSubReason') {
          let master = {...filterApiList[element?.filterApi]}
          master.isQuery = 'categoryId=null'
          if (element.categoryId) {
            master.isQuery = `categoryId=${element.categoryId}`
          }
          element = master
          return element
        }
        if (element?.filterApi == 'getMasterOperationReasons') {
          let master = {...filterApiList[element?.filterApi]}
          master.isQuery = 'category_id=null'
          if (element.categoryId) {
            master.isQuery = `category_id=${element.categoryId}`
          }
          element = master
          return element
        }
        if (element?.filterApi == 'getSubscriptionRenewalReason') {
          let master = {...filterApiList[element?.filterApi]}
          master.isBody = {}
          if (element.categoryId) {
            master.isBody['category_id'] = element.categoryId
          }
          element = master
          return element
        }

        if (element?.filterApi == 'getMasterReasonsForSubscription') {
          let master = {...filterApiList[element?.filterApi]}
          master.isBody = {}
          if (element.categoryId) {
            master.isBody['category_id'] = element.categoryId
          }
          element = master
          return element
        }
      }
      return filterApiList[element?.filterApi]
    })
    .filter((removeUnknowApi: any) => removeUnknowApi !== undefined)

  // return api
  // .map((element: any) => filterApiList[element])
  // .filter((removeUnknowApi: any) => removeUnknowApi !== undefined)
}

export const adminKeyWiseComponents: any = () => {
  let component = {
    multi: AdminMultiSelectFilter,
    multiDate: AdminMultiDateFilter,
    select: AdminSingleSelectFilter,
    selectDate: AdminSingleDateFilter,
    checkbox: AdminSelectCheckbox,
    textBox: AdminSelectTextBox,
    textArea: AdminSelectTextAreaBox,
    textEditor: AdminSelectJodEditor,
    textTag: AdminTagsInput,
  }

  return component
}

export const checkNullValue: any = {
  getSupervisorList: 'getSupervisorList',
  getAlladminlist: 'getAlladminlist',
}

export const adminGetFilterDataBySearch = async (
  dynFilter: any,
  setFilterDataList: any,
  filterDataList: any,
  setIsLoadingFilter?: any,
  payload?: any
) => {
  let filterApi = dynFilter?.filterApi
  const result =
    (await postRequest(URL_EndPoints()?.[filterApi], payload, setIsLoadingFilter)) || []

  let newData = result?.data?.data?.map((ele: any) => {
    ele.first_name = ele?.first_name ?? 'NA'
    ele.last_name = ele?.last_name ?? 'NA'
    ele.phone = ele?.phone ?? 'NA'
    return ele
  })
  setFilterDataList({...filterDataList, [filterApi]: newData})
}

export const validateForm = (formData: any = [], payload: any = {}, setFormErrors: any) => {
  const errors: any = {}

  formData.forEach((element: any) => {
    if (element.isRequired) {
      if (element.valueType == 'multiDate' && (!payload[element.from] || !payload[element.to])) {
        errors[element.from] = `${element.errMessage} `
        errors[element.to] = `${element.errMessage}`
      } else if (
        element.valueType === 'multi' &&
        Array.isArray(payload[element.name]) &&
        payload[element.name].length == 0
      ) {
        errors[element.name] = `${element.errMessage}`
      } else if (element.name && !payload[element.name]) {
        if (payload[element.name] == '0') {
          errors[element.name] = ''
        } else {
          errors[element.name] = `${element.errMessage}`
        }
      }
    }
  })

  setFormErrors && setFormErrors(errors)
  return Object.keys(errors).length === 0
}

function getValue (obj: any, keys: string) {
  const keyArr = keys.split('.')
  const getNestedValue = (obj: any, keysArr: any): any => {
    if (keysArr.length === 0) {
      return obj
    }
    const key = keysArr.shift()
    const isArrayIndex = key.includes('[') && key.includes(']')
    if (isArrayIndex) {
      const [arrKey, indexStr] = key.split('[')
      const index = parseInt(indexStr.replace('[', '').replace(']', ''))
      return getNestedValue(obj[arrKey][index], keysArr)
    } else {
      return getNestedValue(obj[key], keysArr)
    }
  }

  return getNestedValue(obj, keyArr)
}

export const filteredSelectedRowsIds = (filteredSelectedRows: any = [], keyName: any = '') => {
  let filter_Ids: any = []
  filteredSelectedRows?.forEach((row: any) => {
    let id = row?.id
    if (keyName) {
      id = getValue(row, keyName)
    }
    if (id) filter_Ids?.push(id)
  })
  return filter_Ids?.filter((id: number) => id !== undefined && id !== 0)
}

export const adminToastRunner = (response: any, message?: any) => {
  let msgKey = response?.data?.message ? 'message' : 'msg'
  toast?.[response?.data?.status == 200 ? 'success' : 'error'](response?.data?.[msgKey], {
    position: 'top-center',
  })
}

export const adminQueryGenerator = (apiUrlEndPoint: string, payload: any = {}) => {
  if (Object.keys(payload)?.length == 0) return null
  else
    return {
      To: apiUrlEndPoint,
      What: Object.keys(payload)
        .map((query: any, index: any) =>
          index == 0 ? `?${query}=${payload[query]}` : `&${query}=${payload[query]}`
        )
        .join(''),
    }
}

export const adminParamsGenerator = (
  apiUrlEndPoint: string,
  param_id: string | number | null | undefined
) => {
  if (param_id == null || param_id == undefined) return null
  else {
    let newQueryParams = {...QueryParams(apiUrlEndPoint, '/')}
    newQueryParams.What += param_id
    return newQueryParams
  }
}

export const sortDateWithNull = (date1: any, date2: any) => {
  let parsedDate1: any = date1 ? date1 : ''
  let parsedDate2: any = date2 ? date2 : ''

  if (parsedDate1 > parsedDate2) {
    return 1
  } else if (parsedDate1 < parsedDate2) {
    return -1
  }
  return 0
}

export const adminMomentDueDate = (startOrEndDay: number = 0) => {
  return moment().add(startOrEndDay, 'days').format('YYYY-MM-DD')
}

export const formValidationErrorChecker = (payload: any, schema: any, setErrors: any) => {
  const errors: any = {}
  for (const key in schema) {
    if (schema.hasOwnProperty(key)) {
      if (schema[key].required && Array.isArray(payload[key]) && payload[key].length == 0) {
        errors[key] = schema[key]?.errorMessage
      } else if (schema[key].required && !payload[key]) {
        errors[key] = schema[key]?.errorMessage
      }
    }
  }
  setErrors(errors)
  if (Object.keys(errors).length == 0) {
    return true
  } else {
    return false
  }
}

export const enableFeatures4ChampDashboard = (from: any) => {
  return enableFeatureList[from]
}
export const enableFeatures4CustomerDashboard = (from: any) => {
  return enableFeatureList4CustomerDashbaord[from]
}

export const checkEnableFeatures4ChampDashboard: any = (from: any) => {
  return from in enableFeatureList
}

export const checkEnableFeatures4CustomerDashboard: any = (from: any) => {
  return from in enableFeatureList4CustomerDashbaord
}

export const checkValueIsNullOrZero: any = (keyName: any) => {
  return keyName ? keyName : keyName == 0 ? 0 : 'NA'
}

export const globalSetPending: any = (dispatch: any, type: any) => {
  dispatch({type: type, payload: false})
}

export const dateFormater: any = (date: any) => {
  return date ? moment(date).format('DD-MM-YYYY') : 'NA'
}
let baseModifiedRoute = '/api/admin/'
export const useAdminListApiUrl = () => {
  const listApiUrl = (list_api_url: string) => {
    return {
      list_api_complete_url: baseModifiedRoute + URL_EndPoints()?.[list_api_url],
      list_api_url: list_api_url,
    }
  }

  return {listApiUrl}
}

export const useBaseURL: any = () => {
  const isTestOrAdminApiRun = JSON.parse(localStorage.getItem('API') || '0')
  return isTestOrAdminApiRun
    ? 'https://adminapi.carselonadaily.com/api/admin/'
    : 'https://testadminapi.carselonadaily.com/api/admin/'
}

export const useAdminSmartFilterList = () => {
  const dispatch = useDispatch()
  const adminGetSmartFilterList = (list_api_url: string) => {
    let create_url = baseModifiedRoute + URL_EndPoints()?.[list_api_url]
    dispatch(getSmartFilterAction({list_api_url: create_url}) as any)
    return 'Smart Filter Data Feched Successfully'
  }
  return {adminGetSmartFilterList}
}

export const colWidthsHotTable = (columns: any, fullNameColumnLength: any, w1: any, w2: any) => {
  return Array.from({length: columns?.length}, (_, index) =>
    index < fullNameColumnLength ? w1 : w2
  )
}

export const rocordTotalFound = (filterData: any) => {
  return filterData?.reduce((total: any, obj: any) => total + obj.positions_required, 0)
}

export const cellRenderInner = (args: any, Handsontable: any) => {
  const [instance, td, row, col, prop, value, cellProperties] = args
  Handsontable.renderers.TextRenderer.apply(this, args)
  if (value > 0) {
    td.style.backgroundColor = '#e8f0fe'
  }
}

export const hotInstanceLoader = (hotTableComponentRef: any) => {
  const hotInstance = hotTableComponentRef.current.hotInstance
  hotInstance.getPlugin('multiColumnSorting').sort({column: 0, sortOrder: 'asc'})
  hotInstance.getPlugin('multiColumnSorting').sort([
    {column: 1, sortOrder: 'asc'},
    {column: 0, sortOrder: 'desc'},
  ])
}

export const searchFieldKeyupCallback = (event: any, mainData: any, setFilterData: any) => {
  const searchTerm = event.target.value
  const filteredData = mainData.filter((row: any) => {
    return Object.values(row).some((val: any) =>
      // value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      {
        if (typeof val == typeof 'str' && val !== '') {
          if (val.toLowerCase().includes(searchTerm.toLowerCase())) {
            return row
          }
        }
        if (typeof val == typeof 6) {
          let newStr = val.toString()
          if (typeof newStr == typeof 'str' && newStr !== '') {
            if (newStr.includes(searchTerm)) {
              return row
            }
          }
        }
      }
    )
  })
  setFilterData(filteredData)
}

export const handleAfterSelectionRow = (
  setFilteredSelectedRows: any,
  row: any,
  column: any,
  row2: any,
  column2: any,
  preventScrolling: any,
  selectionLayerLevel: any
) => {
  const startRow = Math.min(row, row2)
  const endRow = Math.max(row, row2)
  setFilteredSelectedRows((prevSelectedRows: any) => {
    // const newSelectedRows = [...prevSelectedRows]
    const newSelectedRows: any = []

    for (let i = startRow; i <= endRow; i++) {
      if (!newSelectedRows.includes(i)) {
        newSelectedRows.push(i)
      }
    }
    return newSelectedRows
  })
}

export const getFilterSelectedRowsData = (
  filteredSelectedRows: any,
  filterData: any,
  setChartData: any
) => {
  return setTimeout(() => {
    if (filteredSelectedRows?.length) {
      const mapData: any = []
      filteredSelectedRows?.forEach((index: any) => {
        mapData.push(filterData[index])
      })
      setChartData(mapData)
    }
  }, 1000)
}

export const getFilteredRowDataNew2 = (filteredSelectedRows: any, filterData: any) => {
  const mapData: any = []
  filteredSelectedRows?.forEach((index: any) => {
    mapData.push(filterData[index])
  })
  return mapData
}

export const isAppRunningInTestOrProd: any = {
  'localhost:3011': 'localhost:3011',
  'testadmin.carselonadaily.com': 'testadmin.carselonadaily.com',
}

export const AdminBaseUrl = (endUrl: any) => {
  return 'https://testadminapi.carselonadaily.com' + endUrl
}

export const getType = (value: any): string => {
  if (Array.isArray(value)) {
    if (value.length === 0) return 'Array<any>'
    const arrayTypes = new Set(value.map(getType))
    return `Array<${Array.from(arrayTypes).join(' | ')}>`
  }
  if (value === null) {
    return 'null'
  }
  if (typeof value === 'object') {
    const entries = Object.entries(value).map(([key, val]) => {
      return `${key}: ${getType(val)}`
    })
    return `{ ${entries.join('; ')} }`
  }
  return typeof value
}

export const getApiBaseConfig: any = () => {
  const tokens = localStorage.getItem('auth-token')
  const userId = localStorage.getItem('user')
  const webKit_hash_id = localStorage.getItem('webKit_hash_id')
  return {
    token: tokens,
    user_id: userId,
    webKit_hash_id,
  }
}

export const makeApiRequest = (singleApi: any, key: any, method: any, payload: any, item: any) => {
  if (singleApi) {
    let FetchBody: any = {
      method: method,
      headers: {
        Authorization: `Bearer ${
          getApiBaseConfig()?.['token']
            ? getApiBaseConfig()?.['token'] +
              ',' +
              'Useragenthash ' +
              getApiBaseConfig()?.['webKit_hash_id']
            : ''
        }`,
        Userid: `${getApiBaseConfig()?.['user_id']}`,
        Usertype: `${5}`,
        'Content-Type': 'application/json',
      },
    }

    if (payload) {
      FetchBody.body = JSON.stringify(payload)
    }

    let url: any = new URL(singleApi)

    return new Promise((resolve, reject) => {
      fetch(url.toString(), FetchBody)
        .then((res) => res.json())
        .then((res) => {
          return resolve({[key]: res})
        })
        .catch((error: any) => {
          return reject(error)
        })
    })
  }
}

export const setRunFilter = async (
  event: any,
  name: any,
  filterData: any,
  getCurrentFilter: any,
  setSmartFitlerRowData: any
) => {
  if (event) {
    setSmartFitlerRowData && setSmartFitlerRowData(event)
    let filterObject = JSON.parse(JSON.stringify(event?.filter_obj))
    let keyValuePairObject = Object.keys(filterObject || {}).reduce((obj: any, key: any) => {
      obj[key] = key
      return obj
    }, {})

    const currentAllFilterList = filterData?.filter(
      (smartFilter: any) => keyValuePairObject[smartFilter?.key]
    )
    const EndPointURLArr = currentAllFilterList
      ?.filter((smartFilter: any) => smartFilter?.valueConfig?.callApi)
      ?.map((smartFilter: any) => {
        let reqMethod = smartFilter?.valueConfig?.apiConfig?.method
        let reqApi = smartFilter?.valueConfig?.callApi
          ? smartFilter?.valueConfig?.apiConfig?.APIurl
          : null
        let reqPayload = smartFilter?.valueConfig?.apiConfig?.requestData
        let reqFrom = smartFilter?.key
        return {
          url: reqApi,
          key: reqFrom,
          method: reqMethod,
          payload: reqMethod == 'GET' ? null : reqPayload,
          item: smartFilter,
        }
      })

    const response = await Promise.all(
      EndPointURLArr?.map((singleApi: any) =>
        makeApiRequest(
          singleApi?.url,
          singleApi?.key,
          singleApi?.method,
          singleApi?.payload,
          singleApi?.item
        )
      )
    )
      .then((response: any) => {
        return response
      })
      .catch((error) => {
        console.error('Error during API requests:', error)
      })

    let finalTaransformResponse: any = {}
    if (response) {
      response?.forEach((ele: any) => {
        let key = Object.keys(ele)[0]
        if (key) {
          finalTaransformResponse[key] = ele[key]?.data || []
        }
      })
    }

    currentAllFilterList?.length > 0 &&
      getCurrentFilter(
        {
          label: 'smartFilter',
          value: finalTaransformResponse,
          item: currentAllFilterList,
          filter_obj: filterObject,
          smartFilter: true,
        },
        {action: 'select-option'}
      )
  }
}

export const scrollToTopPage = () => {
  return window.scrollTo({top: 0, behavior: 'smooth'})
}

export const getName = (obj: any) => {
  const firstName = obj?.first_name || ''
  const lastName = obj?.last_name || ''
  return firstName || lastName ? (firstName + ' ' + lastName).trim() : ''
}

export const compareFilters = (globalFilter: any = {}, rowFilter: any = {}) => {
  const differences: any[] = []

  let rowKeys = Object.keys(rowFilter)
  let globKeys = Object.keys(globalFilter)

  if (rowKeys?.length > globKeys?.length) {
    return true
  }

  if (rowKeys?.length < globKeys?.length) {
    return true
  }

  rowKeys.forEach((key: any) => {
    if (!globalFilter[key] && rowFilter[key]) {
      differences.push(rowFilter[key])
    } else if (globalFilter[key] && !rowFilter[key]) {
      differences.push(globalFilter[key])
    } else if (JSON.stringify(globalFilter[key]) !== JSON.stringify(rowFilter[key])) {
      differences.push(rowFilter[key])
    }
  })

  return differences?.length > 0 ? true : false
}

export const getDueDate = async (apiUrl: any, payload: any, setPending: any) => {
  setPending(true)
  const dueDate = await postRequest(URL_EndPoints()?.[apiUrl], payload, setPending)
  return dueDate?.data?.data
}

export const useAdminManagementPermission = () => {
  const userAllowedPermissionList = useSelector(
    (store: any) => store.adminReducer.userAllowedPermissionList
  )

  const permissionWrapperContextUtil = (keyName: any) => {
    return userAllowedPermissionList?.includes(keyName)
  }

  const permissionWrapperHideColsUtil = (columns: any) => {
    const showCols = columns?.filter((ele: any) => {
      return !ele?.hideCols || userAllowedPermissionList?.includes(ele?.hideCols)
    })
    return showCols
  }

  return {permissionWrapperContextUtil, permissionWrapperHideColsUtil} as any
}

export const getOnOffAvgPercentage = (on: number[], off: number[]) => {
  if (on.length === 0) {
    on.push(0)
  }

  if (off.length === 0) {
    off.push(0)
  }

  const totalOn = on.reduce((sum, value) => sum + value, 0)
  const totalOff = off.reduce((sum, value) => sum + value, 0)

  const combinedTotal = totalOn + totalOff

  const percentageOn = (totalOn / combinedTotal) * 100
  const percentageOff = (totalOff / combinedTotal) * 100

  return {
    onAvgPer: `${percentageOn.toFixed(1)}%`,
    offAvgPer: `${percentageOff.toFixed(1)}%`,
  }
}

export const freezeColumn = (width: number = 75, columnOrder: any, columnValue: number) => {
  return columnOrder?.[columnValue] || columnOrder?.[columnValue] == 0
    ? ({
        position: 'sticky',
        left: `${columnOrder?.[columnValue] == 0 ? 0 : width * columnOrder?.[columnValue]}px`,
        backgroundColor: '#fff',
        zIndex: 1,
        boxShadow: '3px 0px 3px 0px rgba(0,0,0,0.2)',
        border: 'none',
        borderLeft: '2px solid rgba(0,0,0,0.2)',
      } as {
        position: string
        left: string | number
        backgroundColor: string
        zIndex: number
        boxShadow: string
        border: string
        borderLeft: string
      })
    : {}
}

// const newCols = columnsUpdated?.map((ele: any, index: any) => {
//   let indexValue = index + 1
//   return ({
//     id: indexValue,
//     reorder: true,
//     name: <span id={index}>{ele?.title}</span>,
//     cell: (row: any) => ele?.title,
//     // cell: (row: any) => row?.data[index]?.total,
//     width: "75px",
//     sortable: true,
//     style: freezeColumn(75, {
//       0: 0,
//       1: 1,
//       2: 2,
//       3: 3,
//     }, index),
//     sortFunction: (rowA: any, rowB: any) => {
//       const a = rowA?.data[index]?.total
//       const b = rowB?.data[index]?.total
//       if (a > b) {
//         return 1
//       }
//       if (b > a) {
//         return -1
//       }
//       return 0
//     },
//   })
// })

export const getColumnIds = () => {
  const columns = document.querySelectorAll('.rdt_TableHeadRow [data-column-id]')
  let columnIds = Array.from(columns).map((col) => col.getAttribute('data-column-id'))
  const newColumns = columnIds?.filter(
    (ele: any, index: number) =>
      columnIds[index] !== '0' && columnIds[index] != columnIds[index + 1]
  )
  return newColumns
}

// useEffect(() => {
//   let width = 75
//   let freeze: any = {
//     1: 1,
//     2: 2,
//     3: 3,
//     4: 4,
//   }

//   const applyStyles = () => {
//     const columnIds = getColumnIds();
//     columnIds.forEach((id: any) => {
//       const columnDiv: any = document.querySelector(`[data-column-id="${id}"]`);
//       if (columnDiv && freeze[id]) {
//         columnDiv.style.position = 'sticky';
//         columnDiv.style.left = `${id == 1 ? 0 : (width * (+id) - width)}px`;
//         columnDiv.style.backgroundColor = '#fff';
//         columnDiv.style.zIndex = 2;
//         columnDiv.style.boxShadow = '3px 0px 3px 0px rgba(0,0,0,0.2)';
//         columnDiv.style.border = 'none';
//         columnDiv.style.borderLeft = '2px solid rgba(0,0,0,0.2)';
//       }
//     });

//   };
//   applyStyles();
// }, [columns])

export const setIsChatOpenedOrNot = (
  value: number | string | null,
  remove: boolean,
  chatName: string
) => {
  let openedChatIds = JSON.parse(localStorage.getItem('notification_ids') || '{}') || {}

  if (remove) {
    openedChatIds.for = 'removed'
    openedChatIds.id = 'not_chat_opend'
  } else {
    if (value) {
      openedChatIds.for = chatName
      openedChatIds.id = value
    }
  }

  localStorage.setItem('notification_ids', JSON.stringify(openedChatIds))
}

export const getNestedValue = (obj: any, path: string) => {
  return path.split('.').reduce((value, key) => value?.[key], obj)
}

export const clientSideSortingFeatureApply = (column: any, sortDirection: any, filterData: any) => {
  const sortedData = [...filterData]?.sort((a: any, b: any) => {
    const fieldA = getNestedValue(a, column?.sortField)
    const fieldB = getNestedValue(b, column?.sortField)
    if (fieldA < fieldB) {
      return sortDirection === 'asc' ? -1 : 1
    }
    if (fieldA > fieldB) {
      return sortDirection === 'asc' ? 1 : -1
    }
    return 0
  })

  return sortedData
}

export const genereteMenuTitle = (title: string) => {
  const newTitle = title
    ?.split('-')
    ?.reduce((str: any, ele: any) => (str += ele.charAt(0).toUpperCase() + ele.slice(1) + ' '), '')
  return newTitle
  // genereteMenuTitle(adminMenuActionPermissionKeys["roles-and-permissions"])
}

export const createFeatureLikeSubMenu = ({name, Id, menu_id}: any) => {
  return {
    ID: Id,
    name: name,
    menu_id: menu_id,
 
  }
}

export const checkActionPerformedAfterCall = async (apiUrl: any, payload: any, setPending: any) => {
  const response = await postRequest(URL_EndPoints()?.[apiUrl], payload, setPending)
  return response?.data?.data
}

export const setStatusLocalStorage = (key: any, value: any, ) => {
  localStorage.setItem(key, value);
  return
}

export const getStatusLocalStorage = (key: any) => {
  return localStorage.getItem(key)
}
